import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ProjectCardListing from '../components/ProjectCardListing';
import ProjectCategoriesNav from '../components/ProjectCategoriesNav';

const ProjectsArchive = () => {
  const {
    datoCmsProjectsArchive: { seoMetaTags, title, bannerText },
    allDatoCmsProject: { nodes: projects },
  } = useStaticQuery(graphql`
    query ProjectsArchiveQuery {
      datoCmsProjectsArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
      }
      allDatoCmsProject(
        sort: {fields: position}
      ) {
        nodes {
          ...ProjectCardFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} text={bannerText} />
        <ProjectCategoriesNav />
        <ProjectCardListing items={projects} />
      </main>
    </Layout>
  );
};

export default ProjectsArchive;
